<template>
  <v-form v-model="isValid" @submit.prevent="handleSubmitForgotYourPassword">
    <base-text-field
      v-model="formData.email"
      type="email"
      :label="$t('email')"
      :placeholder="$t('placeholderEmail')"
      :rules="rulesEmail"
    />

    <div class="d-flex justify-end">
      <base-btn
        type="submit"
        color="secondary"
        :loading="loading"
        :disabled="!isValid || loading"
      >
        {{ $t("send") }}
      </base-btn>
    </div>
  </v-form>
</template>
<script>
import { i18n } from "@/plugins/i18n";
import { authCtrl } from "@/controllers";
import { rulesEmail, getErrorPayloadForSnackbar } from "@/helpers";
import { useSnackbar } from "@/utils";

export default {
  name: "ForgotYourPasswordForm",
  inject: ["handleCloseDialog"],
  data() {
    return {
      loading: false,
      isValid: false,
      formData: { email: "" },
      rulesEmail,
    };
  },
  methods: {
    async handleSubmitForgotYourPassword() {
      this.loading = true;
      authCtrl
        .handleForgotYourPassword({ ...this.formData })
        .then(() => {
          const snackbar = useSnackbar();
          snackbar.dispatchOpenSnackbar(i18n.t("forgotYourPasswordNotify"));
          this.handleCloseDialog();
        })
        .catch((error) => {
          const errorPayload = getErrorPayloadForSnackbar(error);
          this.$store.commit("user/OPEN_SNACKBAR", errorPayload);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
